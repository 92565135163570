var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 pl-5",
      },
      [
        _c("div", { staticClass: "kt-error-v1__container" }, [
          _c("h1", { staticClass: "kt-error-v1__number" }, [
            _vm._v(" Page not found "),
          ]),
          _c("p", { staticClass: "kt-error-v1__desc mt-4" }, [
            _vm._v(" It seems like you have been taken to a bad link. "),
            _c("a", { attrs: { href: "/home" } }, [_vm._v(" Click here ")]),
            _vm._v(" to go home. "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }