<template>
<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 pl-5">
    <div class="kt-error-v1__container">
        <h1 class="kt-error-v1__number">
            Page not found
        </h1>
        <p class="kt-error-v1__desc mt-4">
            It seems like you have been taken to a bad link.
            <a href="/home">
                Click here
            </a> to go home.
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'PageNotFound',
};
</script>
